@import '@core/styles/vars';

.CopilotInstalledPage {
  justify-content: center;
  padding: 1em;
  width: 100%;

  &-content {
    max-width: var(--dash-container-md);
  }
}
